<template>
  <div class="Conocimiento">
    <section class="pt240 pb160 pt-xs-80 pb-xs-80 image-bg page-title">
      <div class="background-image-holder">
        <img
          alt="Pic"
          class="background-image"
          :style="'display:none!important'"
        />
      </div>
    </section>
    <section class="contained-page p0">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1 pt64 pb64 bg-secondary">
            <h2
              class="uppercase text-center mb64 mb-xs-24"
              v-text="articulo.titulo_pub"
            />
            <div class="row">
              <div class="col-md-8 col-md-offset-2">
                <div
                  class="mb40 mb-xs-24"
                  v-html="articulo.contenido_pub"
                />
                <br>
                <button
                  v-if="articulo.id"
                  class="btn btn-filled"
                  style="background-color:#3b5998!important;border-color:#3b5998"
                >
                  <ShareNetwork
                    style="color:white!important"
                    network="facebook"
                    :url="$theUrl+$route.params.id+'/'+$route.params.urlfriendly"
                    :title="articulo.titulo_pub"
                    :description="articulo.descripcion_pub"
                    hashtags=""
                  >
                    <span>Compartir en Facebook</span>
                  </ShareNetwork>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-12 col-md-12">
                <br><br><br><br>
                <h4 class="text-center uppercase mb64 mb-xs-40">Otros articulos que te pudieran interesar</h4>
              </div>
              <div class="col-sm-12 col-md-10 col-md-offset-1">
                <div class="row">
                  <div
                    class="col-sm-6"
                    v-for="(art,idxa) in articulosproximos"
                    :key="idxa"
                  >
                    <a class="fade-on-hover">
                      <img
                        class="mb24"
                        alt="Pic"
                        :src="$baseUrl+art.imagen_pub"
                      />
                    </a>
                    <h4
                      class="uppercase"
                      v-text="art.titulo_pub"
                    />
                    <p v-text="art.descripcion_pub" />
                    <router-link
                      class="btn btn-filled"
                      :to="'/articulo/'+art.id+'/'+art.url_pub"
                    >Leer más</router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import forms from "@/mixins/forms.js";
export default {
  name: "ConocimientoSingle",
  mixins: [forms],
  data() {
    return {
      articulo: {
        id: null,
        titulo_pub: null,
        url_pub: null,
        descripcion_pub: null,
        contenido_pub: null,
        fecha_pub: null,
        imagen_pub: null,
        publico: null,
      },
      articulosproximos: [],
    };
  },
  methods: {
    async _getArticulo() {
      this.cargando = true;
      try {
        const { status, body } = await this.$http.get(
          "articulo/" + this.$route.params.id
        );
        this.cargando = false;
        if (status === 200 && "articulo" in body) {
          this.articulo = body.articulo;
        } else {
          this.$router.push("/articulo-404");
        }
      } catch (err) {
        this.cargando = false;
        this.$router.push("/articulo-404");
      }
    },
    _getProximosArticulos() {
      this._getThings(
        "articulosproximos/" + this.$route.params.id,
        "articulosproximos"
      );
    },
  },
  created() {
    this._getArticulo();
    this._getProximosArticulos();
  },
  watch: {
    $route(a, b) {
      this._getArticulo();
      this._getProximosArticulos();
    },
  },
};
</script>